<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-5 col-lg-4">
          <div class="card overflow-hidden">
              <div class="card-header">
                  <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle bg-light">
                          <img class="mx-auto d-block" src="@/assets/images/login/logo.png" height="80" />
                      </span>
                  </div>
              </div>
              <div class="card-body">
                  <b-overlay :show="loading">
                    <div class="p-2">
                      <b-alert :show="errorAlert" variant="" style="padding:5px" dismissible fade class="alert alert-danger">
                        <div class="iq-alert-icon">
                          <i class="ri-information-line"></i>
                        </div>
                        <div class="iq-alert-text">{{ errorMsg }}</div>
                      </b-alert>
                      <p class="text-center alert alert-success" style="font-size:12px; font-size: 12px; padding: 4px;margin-bottom: -8px;" v-if="warnAlert" >
                        {{ $t('forgot_password.instruction')}}
                      </p>
                      <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(sendOtp)" @reset.prevent="reset" autocomplete="off">
                          <ValidationProvider name="Mobile or Email" vid="mobile_or_email" rules="required|min:6">
                            <b-form-group style="margin-bottom:0px"
                              class="row"
                              label-cols-sm="12"
                              label-for="mobile_or_email"
                              slot-scope="{ valid, errors }"
                            >
                              <b-form-input
                                id="mobile_or_email"
                                v-model="formData.mobile_or_email"
                                :placeholder="$t('forgot_password.otpSendingPlaceholder')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                            <div class="row">
                                <div class="col text-center mt-3">
                                  <button class="btn btn-primary btn-block" type="submit">{{ $t('forgot_password.sendPasswordResetCode') }}</button>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <router-link to="/auth/login">{{ $t('globalTrans.back_to_login') }}</router-link>
                            </div>
                        </b-form>
                      </ValidationObserver>
                    </div>
                  </b-overlay>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { forgotPasswordOtpSendApi } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationObserver, ValidationProvider },
  data: () => ({
    loading: false,
    defaultValue: '',
    formData: {
      mobile_or_email: '',
      id: ''
    },
    warnAlert: true,
    errorAlert: false,
    errorMsg: ''
  }),
  created () {
    this.formData.id = this.$route.params.id
  },
  methods: {
    async sendOtp () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, forgotPasswordOtpSendApi, this.formData)
        if (result.success) {
          this.$router.push({ name: 'authSignUp.forgot_password.otp_verification', params: { mobileOrEmail: this.formData.mobile_or_email } })
        } else {
          if (result.message) {
            this.errorAlert = true
            this.errorMsg = result.message
          } else {
            this.errorAlert = false
            this.$refs.form.setErrors(result.errors)
          }
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.errorMsg = this.$t('forgot_password.otpSendingerror')
      }

      this.loading = false
    },
    async resendCode () {
      try {
        this.loading = true
        const result = await RestApi.postData(authServiceBaseUrl, forgotPasswordOtpSendApi, this.formData)
        if (result.success === true) {
          this.errorAlert = false
          this.warnAlert = true
        } else {
          if (result.message) {
            this.errorAlert = true
            this.warnAlert = false
            this.errorMsg = result.message
          }
        }
      } catch (error) {
        if (error) {
          //
        }
        this.errorAlert = true
        this.warnAlert = false
        this.errorMsg = this.$t('forgot_password.otpSendingerror')
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
        .card{border: 0px!important;}
        .card-header{background-color: #3DB043 !important; border-bottom: 0px!important;}
        .btn-custom{background-color: #596DE1; color: #fff;}
</style>
